<template>
    <div>
        <Bar></Bar>
        <router-view></router-view>
        <Bottom  v-if="$route.path.indexOf('help') == -1"></Bottom>
    </div>
</template>
<script>
import Bar from "@/components/top-bar.vue"
import Bottom from "@/components/bottom.vue"
export default {
    components:{
        Bar,
        Bottom
    }
}
</script>
<style lang="less" scoped>

</style>